import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';

import logo from '../../../../assets/images/Logo.png';

import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    width: 120,
    marginLeft: 'auto',
    marginTop: 15,
    color: 'grey',
    fontSize: 16,
    textAlign: 'center',
    position: 'absolute',
    right: 0,
  },
  main_title: {
    fontSize: 18,
    fontFamily: 'ArialRegular',
    color: '#01B9F2',
    marginTop: 10,
  },
  input: {
    border: 1,
    width: 200,
  },
  label: {
    fontSize: 6,
    marginLeft: 3,
    marginTop: 1,
  },

  text_number_wrapper: {
    marginTop: 30,
  },
  text_number: {
    fontFamily: 'ArialBold',
    fontSize: 9,
  },
  row: {
    flexDirection: 'row',
    gap: 10,
  },
  sub_title: {
    fontFamily: 'ArialRegular',
    fontSize: 12,
    color: '#727378',
    marginTop: 5,
  },
  accent_text: {
    fontFamily: 'ArialBold',
    fontSize: 8,
    color: '#01B9F2',
    marginBottom: 3,
  },
  title: {
    fontFamily: 'ArialBold',
    fontSize: 8,
    color: '#000',
    marginBottom: 2,
  },
  text: {
    fontFamily: 'ArialRegular',
    fontSize: 7,
  },
  left: {
    marginLeft: '35%',
  },
  block: {
    position: 'relative',
    backgroundColor: '#ECECED',
    paddingHorizontal: 15,
    paddingVertical: 5,
    marginBottom: 5,
  },
  number: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: -15,
    width: 15,
    height: 15,
    backgroundColor: '#01B9F2',
  },
  number_txt: {
    fontFamily: 'ArialRegular',
    fontSize: 10,
    color: '#fff',
  },
  section: {
    width: '50%',
  },
  text_area: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 2,
  },
  column: {
    width: '100%',
  },

  space_between: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  inline: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
  },

  stroke: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#01B9F2',
    padding: 3,
  },

  footer: {
    fontSize: 6,
    color: 'grey',
    width: 250,
  },
});

const FirstPageNO = ({ data }) => {
  const { translations } = getTranslations('grenke_first_page_no');

  const ramValues = data && data.services.find((item) => item.name === 'Ping RAM');
  const switchValues = data && data.services.find((item) => item.name === 'Ping Switch');

  // const searchStr = 'Ping Plenty'.toLowerCase();
  // const pingPlenty =
  //   data && data.services.find((item) => item.name.toLowerCase().startsWith(searchStr) && item.num_services > 0);

  const pingPlenty = data && data.services.find((item) => item.name === 'Ping Plenty');

  const getSum = () => {
    const ramSum = ramValues?.num_services > 0 ? ramValues.num_services * parseFloat(ramValues?.price_per_month) : 0;
    const switchSum =
      switchValues?.num_services > 0 ? switchValues.num_services * parseFloat(switchValues?.price_per_month) : 0;
    const pingSum =
      pingPlenty?.num_services > 0 ? pingPlenty.num_services * parseFloat(pingPlenty?.price_per_month) : 0;
    const sum = ramSum + switchSum + pingSum;

    return sum;
  };

  const company = data?.company;

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.text_number_wrapper}>
          <Text style={styles.text_number}>18NO02</Text>
        </View>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <Text style={styles.main_title}>{translations.title}</Text>

        <View style={{ ...styles.row, marginBottom: 10 }}>
          <Text style={styles.sub_title}>{translations.sub_title}</Text>
          <View style={styles.left}>
            <Text style={styles.accent_text}>{translations.agreement_num}</Text>
            <Text style={styles.accent_text}>
              {translations.inquiry_number}:{' '}
              <Text style={{ ...styles.text, fontFamily: 'ArialBold', color: '#000' }}>
                {data?.inquiry_number ?? ''}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>1</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.section}>
              <Text style={styles.title}>{translations.block_head_1_1}</Text>
              <Text style={styles.text}>{translations.block_text_1_1}</Text>
              <View style={{ ...styles.text_area, height: 50, marginTop: 5, paddingLeft: 20 }}>
                <Text style={{ ...styles.text, fontSize: 9 }}>{company?.name}</Text>
                <Text style={{ ...styles.text, fontSize: 9 }}>{company?.postal_address}</Text>
                <Text style={{ ...styles.text, fontSize: 9 }}>
                  {company?.zip_code} {company?.state}
                </Text>
              </View>

              <View style={{ ...styles.row, gap: 3, marginVertical: 3 }}>
                <View style={{ ...styles.text_area, ...styles.inline, width: '50%', height: 20 }}>
                  <Text style={styles.text}>{translations.phone_number}</Text>
                  <Text style={styles.text}>{company?.signers?.[0]?.phone_number ?? ''}</Text>
                </View>
                <View style={{ ...styles.text_area, ...styles.inline, width: '50%', height: 20 }}>
                  <Text style={styles.text}>{translations.organization_number}</Text>
                  <Text style={styles.text}>{company?.organization_number}</Text>
                </View>
              </View>

              <View style={{ ...styles.text_area, ...styles.inline, height: 20 }}>
                <Text style={styles.text}>{translations.email}</Text>
                <Text style={styles.text}>{company?.signers?.[0]?.email ?? ''}</Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>
                {translations.block_head_1_2}{' '}
                <Text style={{ fontFamily: 'ArialRegular', fontSize: 7 }}>{translations.block_head_1_2_1}</Text>
              </Text>
              <Text style={styles.text}>{translations.block_text_1_2}</Text>

              <View style={{ ...styles.text_area, height: 97, marginTop: 13, paddingLeft: 20 }}>
                <Text style={{ ...styles.text, fontSize: 9 }}>Pingaway AS</Text>
                <Text style={{ ...styles.text, fontSize: 9 }}>Dronning Eufemias gate 59</Text>
                <Text style={{ ...styles.text, fontSize: 9 }}>0194 Oslo</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.block, backgroundColor: '#01B9F2' }}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>2</Text>
          </View>

          <View style={styles.row}>
            <View style={styles.section}>
              <Text style={{ ...styles.title, color: '#fff' }}>{translations.block_head_2_1}</Text>

              <View style={{ ...styles.row, gap: 3, marginBottom: 3, marginTop: 10 }}>
                <View style={{ ...styles.text_area, width: '80%', height: 25 }}>
                  <Text style={styles.text}>Ping RAM {ramValues?.service_duration}mån</Text>
                </View>
                <View style={{ ...styles.text_area, width: '20%', height: 25 }}>
                  <Text style={styles.text}>
                    {translations.quantity} {ramValues?.num_services}
                  </Text>
                </View>
              </View>

              <View style={{ ...styles.row, gap: 3, marginBottom: 3 }}>
                <View style={{ ...styles.text_area, width: '80%', height: 25 }}>
                  <Text style={styles.text}>Ping Switch {switchValues?.service_duration}mån</Text>
                </View>
                <View style={{ ...styles.text_area, width: '20%', height: 25 }}>
                  <Text style={styles.text}>
                    {translations.quantity} {switchValues?.num_services}
                  </Text>
                </View>
              </View>

              <View style={{ ...styles.row, gap: 3, marginBottom: 3 }}>
                <View style={{ ...styles.text_area, width: '80%', height: 25 }}>
                  <Text style={styles.text}>Ping Plenty {pingPlenty?.service_duration}mån</Text>
                </View>
                <View style={{ ...styles.text_area, width: '20%', height: 25 }}>
                  <Text style={styles.text}>
                    {translations.quantity} {pingPlenty?.num_services}
                  </Text>
                </View>
              </View>

              <View style={{ ...styles.row, gap: 3 }}>
                <View style={{ ...styles.text_area, width: '80%', height: 25 }}>
                  <Text style={styles.text} />
                </View>
                <View style={{ ...styles.text_area, width: '20%', height: 25 }}>
                  <Text style={styles.text} />
                </View>
              </View>
            </View>

            <View style={styles.section}>
              <View
                style={{ ...styles.text_area, ...styles.space_between, marginBottom: 3, marginTop: 10, height: 30 }}
              >
                <View>
                  <Text style={{ ...styles.accent_text, marginBottom: 0 }}>{translations.block_head_2_2}</Text>
                  <Text style={styles.text}>{translations.block_text_2_2}</Text>
                </View>
                <Text style={styles.text}>{getSum()} NOK</Text>
              </View>

              <View style={{ ...styles.text_area, ...styles.space_between, marginBottom: 3, height: 30 }}>
                <Text style={{ ...styles.accent_text, marginBottom: 0 }}>{translations.block_head_2_3} </Text>
                <Text style={styles.text}>{data?.ping_ram_duration} MÅNEDER</Text>
              </View>

              <View style={{ ...styles.text_area, ...styles.space_between, marginBottom: 10, height: 30 }}>
                <Text style={{ ...styles.accent_text, marginBottom: 0 }}>{translations.block_head_2_4}</Text>
                <Text style={styles.text}>650 NOK</Text>
              </View>

              <Text style={{ ...styles.accent_text, color: '#fff' }}>{translations.block_info_2}</Text>
            </View>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.title, marginBottom: 0 }}>{translations.block_head_0}</Text>
          <View style={{ ...styles.row, alignItems: 'center' }}>
            <Text style={styles.text}>{translations.block_text_0}</Text>
            <View style={{ ...styles.text_area, width: 120, height: 20 }} />
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>3</Text>
          </View>

          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={{ ...styles.text, marginBottom: 10 }}>{translations.block_text_3_1}</Text>
              <Text style={styles.text}>{translations.block_text_3_2}</Text>
            </View>
            <View style={styles.column}>
              <Text style={{ ...styles.text, marginBottom: 10 }}>{translations.block_text_3_3}</Text>

              <Text style={{ ...styles.text, marginBottom: 10 }}>{translations.block_text_3_4}</Text>

              <Text style={styles.text}>{translations.block_text_3_5}</Text>
            </View>
            <View style={styles.column}>
              <Text style={{ ...styles.text, marginBottom: 10 }}>{translations.block_text_3_6}</Text>

              <Text style={{ ...styles.text, marginBottom: 5 }}>{translations.block_text_3_7}</Text>

              <View style={{ ...styles.text_area, height: 50, marginBottom: 10 }} />
              <Text style={styles.text}>{translations.block_text_3_8}</Text>
            </View>
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>4</Text>
          </View>

          <Text style={{ ...styles.title, marginBottom: 0 }}>{translations.block_head_4}</Text>
          <Text style={styles.text}>{translations.block_text_4_1}</Text>

          <Text style={{ ...styles.text, marginTop: 10 }}>{translations.block_text_4_2}</Text>

          <View style={{ ...styles.row, marginTop: 10, gap: 15 }}>
            <View>
              <View style={{ ...styles.row, gap: 3, marginBottom: 3 }}>
                <View style={{ ...styles.text_area, height: 30, width: '30%', justifyContent: 'flex-end' }}>
                  <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.date}</Text>
                </View>
                <View style={{ ...styles.text_area, height: 30, width: '70%', justifyContent: 'flex-end' }}>
                  <Text style={{ ...styles.text }}>{translations.name}</Text>
                </View>
              </View>

              <View style={{ ...styles.row, gap: 3, marginBottom: 3 }}>
                <View style={{ ...styles.text_area, height: 47, width: '30%', justifyContent: 'flex-end' }}>
                  <Text style={{ ...styles.text }}>{translations.company_stamp}</Text>
                </View>
                <View style={{ ...styles.text_area, height: 47, width: '70%', justifyContent: 'flex-end' }}>
                  <Text
                    style={{ ...styles.accent_text, fontFamily: 'ArialRegular', fontSize: 15, marginBottom: 'auto' }}
                  >
                    X
                  </Text>

                  <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.signature}</Text>
                </View>
              </View>
            </View>
            <View style={{ ...styles.stroke, width: 200 }}>
              <Text style={{ ...styles.accent_text, fontFamily: 'ArialRegular', marginBottom: 45 }}>
                {translations.block_head_5}
              </Text>

              <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.txt_1}</Text>
              <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>
                {translations.txt_2} <Text style={{ fontFamily: 'ArialRegular' }}>{translations.txt_3}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>
            <Text style={{ color: '#000' }}>{translations.bank}</Text> {'\n'}
            {translations.footer}
          </Text>
        </View>
      </View>
    </Page>
  );
};

FirstPageNO.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FirstPageNO.defaultProps = {
  data: null,
};

export default FirstPageNO;
