import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Frontdesk.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    width: 120,
    marginLeft: 'auto',
    marginTop: 15,
    color: 'grey',
    fontSize: 16,
    textAlign: 'center',
    position: 'absolute',
    right: 0,
  },
  title: {
    fontSize: 18,
    fontFamily: 'ArialBold',
    marginTop: 20,
  },
  input: {
    border: 1,
    width: 200,
  },
  label: {
    fontSize: 6,
    marginLeft: 5,
    marginTop: 1,
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: 14,
    paddingTop: 2,
    paddingLeft: 5,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'ArialBold',
    paddingLeft: 5,
    paddingBottom: 1,
  },
  sectionName: {
    width: '50%',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  squares: {
    height: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  square: {
    width: 15,
    height: 15,
    border: 1,
  },
  inputValues: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    padding: '3px 0 0 5px',
  },
});

const FirstPage = ({ data }) => {
  const { translations } = getTranslations('grenke_first_page');

  const ramValues = data && data.services.find((item) => item.name === 'Ping RAM');
  const switchValues = data && data.services.find((item) => item.name === 'Ping Switch');

  const searchStr = 'Ping Plenty'.toLowerCase();
  const pingPlenty =
    data && data.services.find((item) => item.name.toLowerCase().startsWith(searchStr) && item.num_services > 0);

  const getSum = () => {
    const sum =
      ramValues?.num_services > 0 ? parseFloat(ramValues?.price_per_month) : parseFloat(switchValues?.price_per_month);

    if (pingPlenty?.price_per_month > 0) {
      return sum + parseFloat(pingPlenty?.price_per_month);
    }

    return sum;
  };

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>
        <Text style={styles.title}>{translations.title}</Text>
        <View style={{ ...styles.input, marginBottom: 10, height: 27 }}>
          <Text style={styles.label}>{translations.inquiry_number}</Text>
          <Text style={styles.text}>{data?.inquiry_number}</Text>
        </View>

        <View style={styles.tableHeader}>
          <Text style={styles.sectionName}>{translations.table_header_1}</Text>
          <Text style={styles.sectionName}>{translations.table_header_2}</Text>
        </View>

        <View style={styles.table}>
          <View style={{ ...styles.input, width: '50%' }}>
            <Text style={styles.label}>{translations.organization_number}</Text>
            <Text style={styles.text}>{data?.company.organization_number}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              borderLeft: 'none',
              width: '50%',
            }}
          >
            <Text style={styles.label}>{translations.organization_number}</Text>
            <Text style={styles.text}>556907-3256</Text>
          </View>
          <View
            style={{
              ...styles.input,
              width: '50%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>{translations.name}</Text>
            <Text style={styles.text}>{data?.company.name}</Text>
          </View>

          <View
            style={{
              ...styles.input,
              borderLeft: 'none',
              width: '50%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>{translations.name}</Text>
            <Text style={styles.text}>Frontdesk Nordic AB</Text>
          </View>

          <View
            style={{
              ...styles.input,
              width: '30%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>{translations.manager}</Text>
            <Text style={styles.text}>{data?.company?.signers?.[0]?.name ?? ''}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              width: '20%',
              borderTop: 'none',
              borderLeft: 'none',
            }}
          >
            <Text style={styles.label}>{translations.phone_number}</Text>
            <Text style={styles.text}>{data?.company?.signers?.[0]?.phone_number ?? ''}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              borderLeft: 'none',
              width: '30%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>{translations.manager}</Text>
            <Text style={styles.text}>{data?.manager?.username ?? ''}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              width: '20%',
              borderTop: 'none',
              borderLeft: 'none',
            }}
          >
            <Text style={styles.label}>{translations.phone_number}</Text>
            <Text style={styles.text}>{data?.manager?.phone_number ?? ''}</Text>
          </View>

          <View
            style={{
              ...styles.input,
              width: '100%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>{translations.invoice_address}</Text>
            <Text> </Text>
          </View>

          <View
            style={{
              ...styles.input,
              width: '100%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>{translations.delivery_address}</Text>
            <Text style={styles.text}>
              {data?.company.postal_address}, {data?.company.zip_code}, {data?.company.state}
            </Text>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>{translations.table_header_3}</Text>
          </View>
          <View style={{ ...styles.table, height: 40 }}>
            <View style={{ ...styles.input, width: '80%' }}>
              <Text style={styles.label}>{translations.type}</Text>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Helvetica',
                  paddingLeft: 5,
                }}
              >
                {translations.type_text}{' '}
                {ramValues?.num_services > 0
                  ? `RAM ${parseFloat(ramValues?.price_per_month)}kr/mån`
                  : `Switch ${parseFloat(switchValues?.price_per_month)}kr/mån`}{' '}
                {pingPlenty?.price_per_month > 0
                  ? `+ Ping Plenty ${parseFloat(pingPlenty?.price_per_month)}kr/mån`
                  : ''}
                {'\n'}
                {translations.agreement_id} - {data?.agreement_id}
              </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '20%',
              }}
            >
              <Text style={styles.label}>{translations.serial_no}</Text>
              <Text> </Text>
            </View>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>
              {translations.table_header_4}{' '}
              <Text style={{ fontSize: 8 }}>({translations.table_header_4_description})</Text>
            </Text>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.input, width: '20%' }}>
              <Text style={styles.label}>{translations.rental_period}</Text>
              <Text style={styles.inputValues}>
                {ramValues?.num_services > 0 ? data?.ping_ram_duration : data?.ping_switch_duration}
              </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '20%',
              }}
            >
              <Text style={styles.label}>{translations.service_per_month}</Text>
              <Text style={styles.inputValues}>
                {getSum()}
                kr
              </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '20%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>{translations.payment_period}</Text>
              <Text style={styles.inputValues}>{translations.quarterly}</Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '20%',
              }}
            >
              <Text style={styles.label}>{translations.setup_fee}</Text>
              <Text style={styles.inputValues}>0 kr</Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '20%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>{translations.airfare}</Text>
              <Text style={styles.inputValues}>39 kr</Text>
            </View>

            <View
              style={{
                ...styles.input,
                width: '40%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.bank_no} </Text>
              <View style={styles.squares}>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text>—</Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.input,
                width: '60%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={{ fontSize: 8, padding: 3 }}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>{translations.table_header_5}</Text>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.input, width: '100%' }}>
              <Text style={{ fontSize: 7, padding: 3 }}>{translations.block_text_2}</Text>
            </View>
          </View>
          <View style={{ ...styles.input, width: '100%', marginTop: 5 }}>
            <Text style={{ fontSize: 7, padding: 3 }}>{translations.block_text_3}</Text>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>{translations.table_header_6}</Text>
            <Text style={styles.sectionName}>{translations.table_header_7}</Text>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.input, width: '25%' }}>
              <Text style={styles.label}>{translations.location}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '25%',
              }}
            >
              <Text style={styles.label}>{translations.date}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>{translations.location}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>{translations.date}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>{translations.signature}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>{translations.signature}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.name_clarification}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.name_clarification}</Text>
              <Text> </Text>
            </View>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>{translations.table_header_8}</Text>
            <Text style={styles.sectionName}>{translations.table_header_9}</Text>
          </View>

          <View style={styles.table}>
            <View
              style={{
                ...styles.input,
                width: '50%',
                height: 45,
                padding: 3,
              }}
            >
              <Text style={styles.label}>{translations.block_text_4}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '50%',
                height: 45,
                padding: 3,
              }}
            >
              <Text style={styles.label}>{translations.block_text_5}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.location}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.date}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderTop: 'none',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>{translations.location}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.date}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>
                {translations.signature} {translations.guarantor}
              </Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>{translations.signature}</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>
                {translations.name_clarification} {translations.guarantor}
              </Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>{translations.name_clarification}</Text>
              <Text> </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FirstPage.defaultProps = {
  data: null,
};

export default FirstPage;
